<template>
   <el-dialog title="Creare fisa de risc" :visible.sync="showPopup" append-to-body width="80%">
        
        <el-form label-position="top" ref='my-form' :model='selectedObject' label-width="100%" v-loading="loadingVisible" >
            <el-row :gutter='15'>
                
                <el-col :xs="24" :sm="24" :md="8" :lg="8">
                    <el-form-item label="Client">
                        <el-select filterable allow-create default-first-option class='full-width' v-model="selectedObject.NumeClient">
                            <el-option v-for='item in Clienti' :key="item.Id" :label='item.Denumire' :value='item.Denumire'></el-option> 
                        </el-select>
                    </el-form-item>                    
                </el-col>
                
                <el-col :xs="24" :sm="24" :md="8" :lg="8">
                    <el-form-item label="Observatii">
                        <el-input v-model="selectedObject.Observatii"></el-input>
                    </el-form-item>
                </el-col>
                                
                <el-col :xs="24" :sm="24" :md="8" :lg="8">
                    <el-form-item label="Recomandare">
                        <el-input v-model="selectedObject.Recomandare"></el-input>
                    </el-form-item>
                </el-col>
                
                
            </el-row>
        </el-form>   
        <span slot="footer" class="dialog-footer" >
            <el-button @click="resetForm('my-form')"> Renunta  </el-button>
			<el-button type="primary" @click="save()" > Genereaza </el-button>
        </span>   

    </el-dialog>
</template>


<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";

export default {
    name: "Fisa_risc_dialog",
    extends: BasePage,
    props: {
        Clienti: Array        
    },
    
    data () {
        return {
            showPopup: false,

            selectedObject:{
                NumeClient:'',
                Observatii: '',
                Recomandare: '',    
                IdFisa:''          
            }
        } 
            
    },
    methods:{
        show_me(fisa_intrare){            
            this.showPopup = true

            this.selectedObject = {
                IdFisa: fisa_intrare.Id,
                NumeClient: fisa_intrare.NumeClient,
                Observatii: '',
                Recomandare: ''
            }            
        },
        async save(){           
            Object.keys(this.selectedObject)
                    .forEach(i => { 
                            if(this.selectedObject[i] === "") 
                                this.selectedObject[i] = '-'                                                          
                        })  

            var response = await this.post('dashboard_mecanic/save_fisa_risc', {object: this.selectedObject, id_fisa: this.IdFisa})
            if(response != ''){
                if(response.mesaj === 'inserted') 
                    this.generare_fisa_risc(response.IdFisaRisc)

                this.showPopup = false
            }            
        },
        generare_fisa_risc(id){                
            window.open("/#/fisa_risc_pdf/" + id, '_blank');
        },
        resetForm() {
                this.$refs['my-form'].resetFields()
                this.showPopup = false            
        },
    }
}
</script>