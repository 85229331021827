<template>
    
    <el-dialog title="Inspectie finala" :visible.sync="showPopup" class="large-dialog-class" width="80%" append-to-body>
        <el-form label-position="top" :model='selectedObject' :rules="rules" label-width="100%" ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
           
            <el-row :gutter="15">

                <!--<el-col :span="12">
                    <el-form-item label="Proba de drum" prop="ProbaDeDrum">
                        <el-select class='full-width' v-model="selectedObject.ProbaDeDrum">
                            <el-option label="Fara" value="-"></el-option>
                            <el-option label="Necorespunzator" value="N"></el-option>
                            <el-option label="Corespunzator" value="C"></el-option>
                            <el-option label="Neaplicabil" value="NA"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col> 

                <el-col :span="12">
                    <el-row >
                        <div style="margin-bottom:27px; margin-top:10px" class="isRequired">Parametri reglaje roti</div> 
                    </el-row>
                    <el-row :gutter="5">
                        <el-col :span="12">
                            <el-form-item prop="Dimensional">
                                <el-select filterable allow-create default-first-option class='full-width' v-model='selectedObject.Dimensional' placeholder="Presiune (BAR)" >
                                    <el-option label="Fara" value="-"></el-option>
                                    <el-option v-for="(item,index) in Options.Dimensional" :key="index" :value="item.value"></el-option>                           
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="Functional">
                                <el-select filterable allow-create default-first-option class='full-width' v-model='selectedObject.Functional' placeholder="Cuplu strangere (NM)">
                                    <el-option label="Fara" value="-"></el-option>
                                    <el-option v-for="(item,index) in Options.Functional" :key="index" :value="item.value"></el-option>                           
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col> -->

                <el-col :span="12">
                    <el-row >
                        <div style="margin-bottom:27px; margin-top:10px" >Eficienta franare (%)</div> 
                    </el-row>
                    <el-row :gutter="5">
                        <el-col :span="12">
                            <el-form-item >
                                <el-select filterable allow-create default-first-option class='full-width' v-model='selectedObject.Frane1' placeholder="Frana serviciu (de picior)">
                                    <el-option label="Fara" value="-"></el-option>
                                    <el-option v-for="(item,index) in Options.Frane1" :key="index" :value="item.value" :style="item.style"></el-option>                                                                          
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item >
                                <el-select filterable allow-create default-first-option class='full-width' v-model='selectedObject.Frane2' placeholder="Frana serviciu (de mana)">
                                    <el-option label="Fara" value="-"></el-option>
                                    <el-option v-for="(item,index) in Options.Frane2" :key="index" :value="item.value" :style="item.style"></el-option>                                                    
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col> 

                <el-col :span="12">
                    <el-row >
                        <div style="margin-bottom:27px; margin-top:10px" >Noxe NOx(g/km)</div> 
                    </el-row>
                    <el-row :gutter="5">
                        <el-col :span="12">
                            <el-form-item >
                                <el-select class='full-width' v-model='selectedObject.Noxe1' placeholder="Motoare benzina"> 
                                    <el-option label="Fara" value="-"></el-option>                           
                                    <el-option label="0.97 Euro1" value="0.97 Euro1"></el-option>
                                    <el-option label="0.50 Euro2" value="0.50 Euro2"></el-option>
                                    <el-option label="0.15 Euro3" value="0.15 Euro3"></el-option>
                                    <el-option label="0.08 Euro4" value="0.08 Euro4"></el-option>
                                    <el-option label="0.06 Euro5" value="0.06 Euro5"></el-option>
                                    <el-option label="0.06 Euro6" value="0.06 Euro6"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item >
                                <el-select class='full-width' v-model='selectedObject.Noxe2' placeholder="Motoare diesel">
                                    <el-option label="Fara" value="-"></el-option>
                                    <el-option label="0.97 Euro1" value="0.97 Euro1"></el-option>
                                    <el-option label="0.90 Euro2" value="0.90 Euro2"></el-option>
                                    <el-option label="0.50 Euro3" value="0.50 Euro3"></el-option>
                                    <el-option label="0.25 Euro4" value="0.25 Euro4"></el-option>
                                    <el-option label="0.18 Euro5" value="0.18 Euro5"></el-option>
                                    <el-option label="0.08 Euro6" value="0.08 Euro6"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col> 

                <el-col :span="12">
                    <el-form-item label="Nr. cmd. geometrie">
                        <el-input v-model='selectedObject.Geometrie' placeholder="Convergenta totala punte fata/spate">                            
                        </el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="Faruri">
                        <el-select class='full-width' v-model="selectedObject.Faruri">
                            <el-option label="Fara" value="-"></el-option>
                            <el-option label="Necorespunzator" value="N"></el-option>
                            <el-option label="Corespunzator" value="C"></el-option>
                            <el-option label="Neaplicabil" value="NA"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col> 

            </el-row>

        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="resetForm('my-form')"> Renunta  </el-button>
            <!--<el-button :disabled="disabledSaveButton" type="primary" @click="save('my-form')"> Finalizare </el-button>-->
            <el-button type="primary" @click="save('my-form')"> Finalizare </el-button>
        </span>        
    </el-dialog>

</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Inspectie_finala_dialog",
        extends: BasePage,
        components:{
        },
        computed: {
            disabledSaveButton(){
                let ret = true
                if(this.selectedObject.ProbaDeDrum != '' && this.selectedObject.Dimensional != '' && this.selectedObject.Functional != '')
                    ret = false

                return ret
            }
        },
        watch: {
            areProdusRoti(val){
                if(val){
                    this.rules.Frane1[0].required = true
                    this.rules.Frane2[0].required = true   
                }                             
            }
        },
        data () {
            return {
                showPopup: false,
                selectedObject: {},
                selectedObjectTemplate: {
                    ProbaDeDrum: '',
                    Faruri: '',
                    Noxe1: '', 
                    Noxe2: '',                   
                    Frane1: '',
                    Frane2: '',
                    Dimensional: '',
                    Functional: '',
                    Geometrie: ''
                },
                rules: {
                    ProbaDeDrum:[ { required: true, message: 'Campul este obligatoriu' } ],
                    Faruri:     [ { required: true, message: 'Campul este obligatoriu' } ],
                    Noxe:       [ { required: true, message: 'Campul este obligatoriu' } ],                    
                    Frane1:     [ { required: false, message: 'Campul este obligatoriu' } ],
                    Frane2:     [ { required: false, message: 'Campul este obligatoriu' } ],
                    Dimensional:[ { required: true, message: 'Campul este obligatoriu' } ],
                    Functional: [ { required: true, message: 'Campul este obligatoriu' } ],
                    Geometrie:  [ { required: true, message: 'Campul este obligatoriu' } ],
                                                         
                },
                Options: {
                    Dimensional: [
                        {value: '2'},
                        {value: '2.2'},
                        {value: '2.4'},
                        {value: '2.6'},
                        {value: '2.8'},
                    ],
                    Functional: [
                        {value: '105'},
                        {value: '115'},
                        {value: '125'},
                        {value: '135'},
                        {value: '145'},
                    ],
                    Frane1: [
                        {value: '60', style:"color:red;"},
                        {value: '65', style:""},
                        {value: "70", style:""},
                        {value: '75', style:""},
                        {value: '80', style:""},
                        {value: '85', style:""},
                    ],
                    Frane2: [
                        {value: '20', style:"color:red;"},
                        {value: '25', style:""},
                        {value: "30", style:""},
                        {value: '35', style:""},
                        {value: '40', style:""},
                        {value: '50', style:""},
                    ]
                },
                areProdusRoti:false
            }
        },
        methods: {
            show_me(fisa) {
                this.selectedObject = JSON.parse(JSON.stringify(this.selectedObjectTemplate))
                this.checkProduse(fisa.Id)
                this.showPopup = true                
            },    
            async checkProduse(id){
                var result = await this.post('dashboard_mecanic/check_produse', {id_fisa: id})
                this.areProdusRoti = result.AreProdusRoti
            },
            async save() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                        this.$emit('save', {inspectie: this.selectedObject}) 
                    else 
                        console.log('eroare validare formular!')
                })
            },
            resetForm() {
				this.$refs['my-form'].resetFields() 
				this.showPopup = false
            },
        },
       
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

    .isRequired:before{
        content: '*';
        color: #F56C6C;
        margin-right: 4px;
    }

</style>