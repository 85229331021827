<template>
    <el-dialog title="Produse rapide" :visible.sync="showPopup" class="my-dialog-class" append-to-body width="80%" :show-close='false'>   
        <el-row :gutter="20" style="margin-bottom:30px;">                   

            <el-col :span="24" >

                <el-autocomplete class="full-width" v-model="nume_produs" placeholder="Cauta produs" style="margin-bottom:40px"
                    :fetch-suggestions="cautaProdus" @select="handleSelectProdus">      
                    <template slot-scope="{ item }">
                        <div class="nume">{{ item.ProdusComplet.Nume }}</div>
                        <div class="pret" >{{item.ProdusComplet.Pret |format_money}} lei</div>
                    </template>                      
                </el-autocomplete>

            </el-col>

            <el-col :span="24" v-loading="loadingProduseRapide">
                <p><b>Produse:</b></p>
                <div v-for="(item,index) in Produse" :key="index" class="container-produs">
                    <el-col :span="2"> 
                        <el-tooltip v-if="item.DinProgramare" content="Retur" placement="left">
                            <el-button plain type="warning" icon="el-icon-d-arrow-left" circle @click="deschideRetur(item)" class="pull-left" />
                        </el-tooltip>
                        <el-tooltip v-else content="Sterge" placement="left">
                            <el-button plain type="danger" icon="el-icon-delete" circle @click="delete_item(item)" class="pull-left" />
                        </el-tooltip>
                    </el-col>                    
                    <el-col :span="12" style="padding-left:0px; margin-top:5px">
                        <span style="font-size:20px">{{item.Nume}}</span> 
                    </el-col>
                    <el-col :span="2" style="padding-left:0px; margin-top:5px">
                        <span style="font-size:12px">{{item.Cod}}</span> 
                    </el-col>

                    <!-- daca produsul este tip kit se poate modifica suma -->
                    <div v-if="item.TipProdusInOferta == 'kit'">
                        <el-col :span="1" style="margin-top:8px">
                            <span >Pret</span> 
                        </el-col>
                        <el-col :span="3" style="padding-left:0px;">
                            <el-input-number :precision="2" class="pull-right" label="Pret" v-model="item.Pret"></el-input-number>                                                         
                        </el-col>
                        <el-col :span="4" style="padding-left:0px;">
                            <!-- <el-input-number v-if="item.TipProdusInOferta == 'manopera'" :step="0.5" v-model="item.Cantitate" class="pull-right" ></el-input-number> -->
                            <el-input-number v-model="item.Cantitate" class="pull-right" :disabled="item.DinProgramare"></el-input-number>
                        </el-col> 
                    </div>
                    <div v-else>
                        <el-col :span="6" :offset="2" style="padding-left:0px;">
                            <el-input-number v-if="item.TipProdusInOferta == 'manopera'" :step="0.5" v-model="item.Cantitate" class="pull-right" :disabled="item.DinProgramare"></el-input-number>
                            <el-input-number v-else v-model="item.Cantitate" class="pull-right" :disabled="item.DinProgramare" ></el-input-number>
                        </el-col>   
                    </div>  
                    <!--  -->
                    
                </div>
            </el-col>   
            
        </el-row>         
       
        <span slot="footer" class="dialog-footer" >
            <el-button :disabled="disabledSaveButton" type="info" @click="save"> OK  </el-button>            
        </span>

<!-- confirmare retur -->
        <el-dialog width="70%" title="Retur" :visible.sync="showRetur" append-to-body v-loading="loadingRetur">
            <el-row :gutter="15">
                <el-col :span="20" :offset="2">    
                    <el-row :gutter="15" style="margin-bottom:20px">
                        <el-col :span="12"> Produs </el-col>                                                   
                        <el-col :span="12"> Cantitate returnata </el-col>
                    </el-row>    
                    <el-row :gutter="15" style="margin-bottom:50px">
                        <el-col :span="12" style="margin-top:5px; font-size:20px">
                            <span > {{produsRetur.Nume}} - {{produsRetur.Cod}} </span>
                        </el-col>
                        <el-col :span="12">
                            <el-input-number v-model="produsRetur.Cantitate" :min="1" :max="produsRetur.CantitateMaxima"></el-input-number>                    
                        </el-col>   
                    </el-row>                 
                </el-col>
            </el-row>
            
            
            <span slot="footer" class="dialog-footer">                                                 
                <el-button @click="showRetur = false"> Renunta  </el-button>
                <el-button type="warning" @click="salveazaRetur"> Returneaza </el-button>
            </span>
        </el-dialog>
<!-- end confirmare retur -->

            
    </el-dialog>
</template>

<script>
 import settings from "@/backend/LocalSettings";
import BasePage from '@/pages/BasePage';

export default {
    name: "Produse_rapide_dialog",
    extends: BasePage,
    components: {
    },
    computed: {
        stepProdus(){
            let ret = 1
            this.Produse.forEach(p => {
                if(p.TipProdusInOferta == 'manopera')
                    ret = 0.5
            })
            return ret
        }
    },
    data () {
        return {
            showPopup: false,           
            id_fisa: '',           
            show_piese: false,
            show_manopera: false,
            show_kit: false,
            activeName: '',
            Produse: [],
            size: '',
            offset: Number,
            height_piese: 300,
            Grupe: [],
            nume_produs:'',
            ProdusComplet:{},
            disabledSaveButton:false,
            produsRetur:{Cod:'', Cantitate:'', Nume:''},
            showRetur:false,
            loadingProduseRapide:false,
            loadingRetur:false
            
        }
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);        
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    methods: {       
        handleClick(tab) {
            this.set_height_for_buttons(tab.label)
        },
        myEventHandler(e){
            var w = window.innerWidth;
            if(w > 400) {
                this.offset = 2
                this.size = 'small'
            }
            else {
                this.offset = 0
                this.size = 'mini'
            }            
        },

        show_me(id_fisa){
            this.showPopup          = true;
            this.id_fisa            = id_fisa
            this.disabledSaveButton = false
            this.Produse            =  []
            
            this.get_produse_programare()
            this.get_info();
        },

        async get_produse_programare(){
            this.loadingProduseRapide   = true
            var response                = await this.post("dashboard_mecanic/get_from_programare", {id_fisa: this.id_fisa})
           
            response.ProduseProgramare.forEach(p => {
                    // p.ProdusComplet.IdProdusProgramare = p.Id
                    // p.ProdusComplet.Cod = p.CodProdus
                    this.handleSelectProdus(p, p.Cantitate, true)
                })
            this.loadingProduseRapide   = false
        },

        async get_info(){    
            this.loadingProduseRapide   = true
            var response                = await this.post("dashboard_mecanic/get_produse_from_fisa", {id_fisa: this.id_fisa});
          
            response.Produse.forEach(p => {
                    this.handleSelectProdus(p, p.Cantitate)
                })
            this.loadingProduseRapide   = false           
        },

        async selecteaza(item){               
            let cosGol = true            
            this.Produse.forEach(p => {
                if(p.Id === item.Id) {
                    cosGol = false
                    p.Cantitate++
                }                                     
            })

            if(cosGol) {
                this.$set(item, 'Cantitate', 1)                        
                this.Produse.push(item)
            }                                            
        },

        // querySearch
        async cautaProdus(queryString, cb){
            if(queryString != '')
            {
                var response = await this.post("dashboard_mecanic/search_produs_rapid", {queryString: queryString})
                cb(response.Results)
            }
        },
        handleSelectProdus(item, cantitate = 1, din_programare=false){    
            // console.log('cantitate', cantitate);
                    
            let cosGol = true
            if(!din_programare){
                this.Produse.forEach(p => {
                    if(p.Id === item.ProdusComplet.Id) {
                        cosGol = false
                        p.Cantitate++
                    }                                     
                })
            }
            

            console.log(cosGol);
            
            if(cosGol) {
                this.$set(item.ProdusComplet, 'Cantitate', cantitate)                        
                this.$set(item.ProdusComplet, 'DinProgramare', din_programare)                        
                this.Produse.push(item.ProdusComplet)
            }
            
            this.nume_produs = ''
        },
        // 

        async save(){            
            this.disabledSaveButton = true
            if(this.Produse.length > 0){
                var response = await this.post("dashboard_mecanic/set_servicii_rapide", {produse: this.Produse, id_fisa: this.id_fisa});
                this.$message({type: 'success', message: 'Produsele au fost adaugate'});
                this.showPopup = false;
            } else this.showPopup = false;
        },
        
        delete_item(item){
            var index = this.Produse.indexOf(item)
            this.Produse.splice(index,1) 
        },

        deschideRetur(item){
            item.CantitateMaxima    = item.Cantitate
            this.produsRetur        = item            
            this.showRetur          = true
        },
        async salveazaRetur(){           
            this.loadingRetur = true
            
            await this.post("dashboard_mecanic/retur_produs_programare", {produs: this.produsRetur})

            if(this.produsRetur.Cantitate == this.produsRetur.CantitateMaxima){
                var index = this.Produse.indexOf(this.produsRetur)
                this.Produse.splice(index,1) 
            }
            else{
                this.Produse.forEach(p => {
                    if(p.Id === this.produsRetur.Id) {
                        p.Cantitate = this.produsRetur.Cantitate
                    }                                     
                })
            }
            this.$message({type: 'info', message: 'Produsul a fost returnat'});
            this.loadingRetur = false
            this.showRetur = false
        },
        calcul_height(){
            this.height_piese = window.innerHeight - 220
        },
        set_height_for_buttons (activeName,nameState) {                        
            this.$nextTick(() => {
                setTimeout(function() {
                    var butoane     = document.getElementsByClassName(activeName);                 
                    var maxHeight   = 0;

                    for(var i=0; i<butoane.length;i++) {
                        console.log(butoane[i].clientHeight)
                        if(butoane[i].clientHeight > maxHeight) maxHeight = butoane[i].clientHeight;
                    }

                    for(var i=0; i<butoane.length;i++) {
                        butoane[i].style.height = maxHeight + "px";
                    }

                    console.log('max buton',maxHeight);
                },1500)
                
            });
        }
        
    },
    mounted(){
    //    this.myEventHandler()
    //    this.calcul_height()              
    }
};
</script>

<style lang="less" scoped>
    .no-padding-left{
        padding-left:0px;
    }

    .pull-right{
        float:right;
    }

    .container-produs {
        width:100%;
        float:left;
        border-bottom:1px solid #cecece;
        padding:10px 0;
    }

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

    .active{
        background-color: lightgreen;
    }

    .el-button{
        white-space: normal;
    }

    .el-input-number--mini{
        width: 90px !important;
    }

    .nume {
        width: 40%;
        float:left;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .pret {
        width: 20%;
        float:left;
    }

</style>
