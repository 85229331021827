<template>
   <el-dialog title="Deviz deschis" :visible.sync="showPopup" append-to-body >
        <h5>Masina <span style="color:blue">{{Masina}}</span> are deviz deschis. Doriti adaugarea produselor in acest deviz?</h5>
        <!-- <el-form label-position="top" ref='my-form' :model='selectedObject' label-width="100%" v-loading="loadingVisible" >
            
        </el-form>    -->
        <span slot="footer" class="dialog-footer" >
            <el-button @click="resetForm"> Renunta  </el-button>
			<el-button type="primary" @click="save()" > OK </el-button>
			<el-button type="info" @click="preview_produse" > Vezi deviz </el-button>
        </span>   
    <Deviz-produse-pdf ref="deviz-produse-pdf"/>
    </el-dialog>
</template>


<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Deviz_produse_pdf from '@/pages/devize/Deviz_produse_pdf.vue';

export default {
    name: "Deviz_deschis_dialog",
    extends: BasePage,
    components:{
        'Deviz-produse-pdf': Deviz_produse_pdf, 
    },
    
    data () {
        return {
            showPopup: false,            
            Masina: '',
            IdDeviz:'',
            IdFisa:''
        }
    },
    methods:{
        show_me(obj){            
            this.showPopup  = true
            this.Masina     = obj.Masina
            this.IdDeviz    = obj.IdDeviz
            this.IdFisa     = obj.IdFisa
        },
        preview_produse(){
            this.$refs['deviz-produse-pdf'].show(this.IdDeviz)
        },
        async save(){           
            var response = await this.post("fisa_intrare/seteaza_deviz", {id_fisa: this.IdFisa, id_deviz: this.IdDeviz})
            this.showPopup = false
        },        
        resetForm() {
            this.showPopup = false            
        },
    }
}
</script>