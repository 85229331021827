<template>
    <div>
        <el-dialog title="Fisa intrare" :visible.sync="showPopup" class='my-dialog-class' :show-close='false' :close-on-click-modal='false' width="80%">
            <el-form :label-width="labelWidth" :rules="rules" :model='selectedObject'  ref='my-form' v-loading="fisaInCursDeSemnare">
                <el-row>
                    <el-col :xs="24" :sm="12" :md="12" :lg="12">
                        <el-form-item label='Programare' prop="IdProgramare">
                            <div class="input-group">
                                <el-select v-model='selectedObject.IdProgramare' class="full-width input-group-input" :disabled="arata_programari">
                                    <el-option label='Fara' value='-1'></el-option>        
                                    <el-option v-for='item in Info.programari' :key="'programari' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                                </el-select> 
                                <div class="input-group-append">
                                    <el-button style="padding-left:7px; padding-right:7px;" plain type="success"  @click="handleSelectProgramare">Aplica</el-button>
                                </div>
                            </div>
                        </el-form-item>
                    </el-col>    
                    <el-col :xs="24" :sm="12" :md="12" :lg="12">
                        <el-form-item label='Masina' prop="IdMasina">
                            <div class="input-group">
                               
                                <SelectMasina 
                                    class="full-width input-group-input" 
                                    v-model="numarMasina" 
                                    :NumarMasina="numarMasina" 
                                    :IdMasina.sync="selectedObject.IdMasina" 
                                    @selectie="select_masina" />

                                <div class="input-group-append">
                                    <el-button  plain type="success" icon="el-icon-plus" @click="adaugaMasina"></el-button>    
                                </div>
                            </div>
                        </el-form-item>
                    </el-col> 
                    <el-col :xs="24" :sm="12" :md="12" :lg="12">
                        <el-form-item label='Client' prop="IdClient">
                            <div class="input-group">
                                
                                <SelectClient 
                                    class="full-width input-group-input" 
                                    v-model="numeClient" 
                                    :NumeClient="numeClient" 
                                    :IdClient.sync="selectedObject.IdClient" 
                                    :TriggerFocus="triggerFocus"
                                    :forcedByIdMasina="forcedByIdMasina"
                                    @selectie="select_client" />

                                <div class="input-group-append">
                                    <el-button  plain type="success" icon="el-icon-plus" @click="adaugaClient"></el-button>
                                </div>
                            </div>                        
                            <p v-if="avertismentClientiMultipli" style="word-break:normal; line-height: 1.5; margin-top:10px"><span style="color:red;">Atentie, clienti multipli! <br></span> Selecteaza numele din lista, sau adauga unul nou.</p>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="12">
                        <el-form-item label='Nr. Km' prop="NrKm">
                            <el-input-number class='full-width' v-model.number='selectedObject.NrKm' :precision='0' :min="1" :max='999999'></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row> 
            </el-form>

             <span slot="footer" class="dialog-footer" >
                <el-button @click="closeMe()" > Renunta  </el-button>
                <el-button type="primary" @click="semneaza()" :loading="fisaInCursDeSemnare"> Salveaza si trimite la semnat </el-button>
            </span>
          
            <Masini-dialog ref='dlg'  @save="salvare_masina"/>
            <Clienti-dialog ref='clienti-dlg' @save="refresh_clienti" />
            <ConfirmareSemnaturaFisa ref="confirmareSemnatura" @semnat="closeMe"/>
        </el-dialog>
    </div>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage'; 
    import Masini_dialog from '@/pages/masini/Masini_dialog.vue';
    import Clienti_dialog from '@/pages/clienti/Clienti_dialog.vue';
    import ConfirmareSemnaturaFisa from '@/components/ConfirmareSemnaturaFisa'
    import SelectMasina from '@/components/SelectMasina'
    import SelectClient from '@/components/SelectClient'

    export default {
        name: "Fisa_intrare_dialog",
        extends: BasePage,
        components: {        
        'Masini-dialog': Masini_dialog,
        'Clienti-dialog': Clienti_dialog,
        ConfirmareSemnaturaFisa,
        SelectMasina,
        SelectClient
        },
        data () {
            return {
                labelPosition:'left',
                showPopup: false,
                mode: 'add',
                selectedObjectTemplate: { IdMasina: '' , IdClient: '' , NrKm: '' , IdProgramare: '-1',Semnatura:'' },
                selectedObject: { },
                Info:{
                    masini: [], clienti: [], utilizatori: [], programari: [] },
                rules: {
                    IdMasina:       [ { required: true, message: 'Campul este obligatoriu' } ], 
                    IdClient:       [ { required: true, message: 'Campul este obligatoriu' } ], 
                    NrKm:           [ { required: true, message: 'Campul este obligatoriu' } ], 
                    IdProgramare:   [ { required: true, message: 'Campul este obligatoriu' } ], 
                },
                arata_programari: false,
                arata_clienti: false,
                avertismentClientiMultipli: false,
                labelWidth: '',
                widthSelect: '',
                semnaturaAcordata: false,
                fisaInCursDeSemnare: false,
                intervalVerificare: null,
                numarMasina:'',
                numeClient:'',
                triggerFocus: false,
                forcedByIdMasina:null
            }
        },
        created() {
            window.addEventListener("resize", this.myEventHandler);
        },
        destroyed() {
            window.removeEventListener("resize", this.myEventHandler);
        },
        methods: {

            myEventHandler(){
                var w = window.innerWidth;
                if(w > 500) {
                    this.labelWidth     = '100px'
                    this.widthSelect    = 'width:160px'
                }                
                else {
                    this.labelWidth     = ''
                    this.widthSelect    = ''
                }   
            },
           
            show_me: async function( id ) {
                this.selectedObject     = JSON.parse(JSON.stringify(this.selectedObjectTemplate))
                this.numarMasina        = ''
                this.numeClient         = ''
                var me                  = this                
                this.showPopup          = true;                
                this.arata_programari   = false

                if( id == null ) {
                    this.mode = "add";
                } else {
                    this.mode           = "edit";
                    var result          = await this.post("fisa_intrare/get_info_item_dialog", { id: id } );
                    this.selectedObject = result.Item;
                    if (this.selectedObject.IdProgramare != -1) 
                        this.arata_programari = true;
                }
            },
            
            async select_masina(item){ 
                this.numarMasina            = item.Numar                
                var response                = await this.post('fisa_intrare/get_clienti', {id_masina: this.selectedObject.IdMasina})
                this.Info.clienti           = response.Clienti                
                this.selectedObject.NrKm    = response.UltimulKilometraj

                if(this.Info.clienti != '') 
                    this.arata_clienti = true;

                this.set_nume_client()
            },
            select_client(item){
                this.numeClient = item.Nume
                this.avertismentClientiMultipli = false
            },
            set_nume_client(){
                this.triggerFocus               = false
                this.avertismentClientiMultipli = false
                this.forcedByIdMasina           = null
                this.numeClient                 = ''
                this.selectedObject.IdClient    = ''                

                if(this.Info.clienti.length > 1)
                {
                    this.avertismentClientiMultipli = true
                    this.triggerFocus               = true
                    this.forcedByIdMasina           = this.selectedObject.IdMasina
                }
                if(this.Info.clienti.length == 1)
                {
                    this.numeClient                 = this.Info.clienti[0].Nume
                    this.selectedObject.IdClient    = this.Info.clienti[0].Id
                }
            },
            handleSelectProgramare(){
                var me      = this
                let filtru  = ''
                filtru      = this.Info.programari.filter((value) => value.Id == me.selectedObject.IdProgramare)  

                if(filtru.length > 0)
                {                   
                    this.selectedObject.IdMasina    = filtru[0].IdMasina
                    let item = {Numar:filtru[0].NumarMasina}
                    this.select_masina(item)
                }
            },
            get_info: async function() {
                var response          = await this.post("fisa_intrare/get_info_for_dialog" );                
                this.Info.programari  = response.Programari               
            },
            get_clienti(){
                return this.Info.clienti
            },
            async semneaza() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid) 
                    {
                        this.fisaInCursDeSemnare = true
                        var response = await this.post("fisa_intrare/save", { mode: this.mode, object: this.selectedObject } )
                        if(response.Fisa != ''){
                            this.$refs['confirmareSemnatura'].getContent(response.Fisa.Id)    
                        }                                            
                    } 
                    else 
                        console.log('eroare validare formular!')
                });               
            },

            async verifica_semnatura(){
                var response = await this.post("fisa_intrare/verifica_semnatura", {id: this.selectedObject.Id });
                if( response.Semnat )
                {
                    clearInterval( this.intervalVerificare );
                    this.fisaInCursDeSemnare  = false;
                    this.semnaturaAcordata    = true;
                    this.closeMe();
                }
            },

            save: async function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid) 
                    {
                        var response = await this.post("fisa_intrare/save", { mode: this.mode,pentruSemnatura:pentruSemnatura, trimiteTest:true, object: this.selectedObject } )
                        this.closeMe();
                        this.$emit("save", response.Fisa.Id);
                    } 
                    else 
                        console.log('eroare validare formular!')
                });
            },
            closeMe() {
                this.$refs['my-form'].resetFields()
                this.semnaturaAcordata      = false;
                this.fisaInCursDeSemnare    = false;
                this.showPopup              = false;
                this.$emit("close");
            },            
            adaugaMasina(){
                this.$refs.dlg.show_me();
            },
            adaugaClient(){
                this.$refs['clienti-dlg'].show_me();
            },
            async refresh_clienti(){
                this.get_info();
                var response                  = await this.post('fisa_intrare/get_last_client', {})                         
                this.selectedObject.IdClient  = response.LastClient.Id
            },
            async salvare_masina(){
                this.get_info();
                var response                    = await this.post('fisa_intrare/get_last_masina', {})
                this.selectedObject.IdMasina    = response.LastCarId
                this.selectedObject.IdClient    = response.LastCarClientId
                this.selectedObject.NrKm        = response.UltimulKilometraj
                this.numarMasina                = response.NumarMasina
                this.numeClient                 = response.LastCarNumeClient
            },

            async get_client_masina(){
                var response = await this.post('fisa_intrare/get_client_masina', {id_programare: this.selectedObject.IdProgramare})
                this.selectedObject.IdMasina = response.IdMasina
                this.selectedObject.IdClient = response.LastCarClientId
            },
            makeid(length) {
                var result           = '';
                var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                var charactersLength = characters.length;
                for ( var i = 0; i < length; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            },
            selectieMasina(item){
                console.log(item);
                
                this.numarMasina = item.Numar
            },
            
        },
        mounted: function() {          
            this.get_info();
            this.myEventHandler();            
        }
    };
</script>

<style lang="less" scoped>
    .input-group
    {
        position:relative;
        display:flex;
        flex-wrap:wrap;
        -webkit-box-align: stretch;
        align-items: stretch;
        width:100%;

        .input-group-input
        {
            position:relative;
            -webkit-box-flex: 1;
            flex: 1 1 auto;
            width: 1%;
        }

        .input-group-append
        {
            margin-left: -1px;
            display: flex;
        }
    }

    .full-width {
        width: 100%;
    }

    .border-signature {
      border:2px solid #CECECE;
    }

    .mb-desktop-48 {   
        margin-bottom:0px;
        margin-top:48px;

        @media(min-width:1200px) {
            margin-top: 0px;  
        }
    }

</style>