<template>
<base-page>
    <div class="pitcar">
        <img src="../../assets/pitcar-alb-nou.png" alt="logo" style="height: 70px;" />
    </div>
    <el-row style="margin-top:50px">
        <el-col :md="8">
          <el-card class="car-box-card add-new-car">
            <p style='text-align:center; margin-bottom:0;'>
                <el-button @click='adauga_fisa()'  type='warning' class='full-width' style='height: 200px'>Fisa Noua</el-button>
                <el-button class='full-width' @click="tire_hotel()">Tire Hotel</el-button>
                <el-button @click='redeschidere_fise()' type='success' class='full-width'>Redeschide fise</el-button>
              </p>
          </el-card>
        </el-col>

        <el-col :md="8" v-for="item in fise" :key="item.Id">
            <el-card class="car-box-card">               
                <el-row :gutter='15'>
                    
                    <el-col :span='24'>
                        <el-button style="float: right;margin-top:0px" type="danger" circle size="mini" icon="el-icon-delete" @click="stergeFisa(item.Id)"></el-button>
                        <p style='font-weight: bold'>{{item.Id}} {{item.NumarMasina}} -> {{item.NumeClient}} </p>
                    </el-col>
                    
                    <el-col :md='24'>
                        <el-button  class='full-width' @click='arata_checklist(item)'>Check 360</el-button>
                    </el-col>

                    <el-col :md='24'>
                        <el-button  class='full-width' @click='arata_produse(item)'>Produse rapide</el-button>
                    </el-col>
                    
                    <el-col :md='24'>
                        <el-button class='full-width' v-if="item.IdMecanic2 == '-1'" @click="arata_partener_dialog(item)">Adauga partener</el-button>                        
                        <el-button class='full-width' type="info" v-if="item.IdMecanic2 != '-1'"  @click="arata_partener_dialog(item)"> Partener: {{item.NumePartener}} </el-button>
                        <el-button class='full-width' type="info" @click="fisa_risc(item)">Fisa de risc</el-button>                        
                    </el-col>

                    <el-col :md='24'>
                        <el-button v-if="item.IdProgramare > 0" type='warning' plain class='full-width' @click="listeaza_produse(item)">Listeaza produse</el-button>
                    </el-col>

                    <el-col :md="12">
                        <el-button class="full-width" type="warning" @click="generare_pdf(item.Id)">Print</el-button>
                    </el-col>
                    <el-col :md="12">
                        <el-button class="full-width" type="success" @click="deschideInspectieDialog(item)">Finalizare</el-button>                        
                    </el-col>
                </el-row>
            </el-card>
        </el-col>

    </el-row>    

    <el-dialog title="Lista produse" :visible.sync="ArataListaProduse" width="100%" append-to-body >
        <el-table :data="Produse">
            <el-table-column type="index"></el-table-column>
            <el-table-column label="Nume produs" prop="NumeProdus"></el-table-column>
            <el-table-column label="Cantitate" prop="Cantitate"></el-table-column>
        </el-table>

        <span slot="footer" class="dialog-footer">
            <el-button @click="ArataListaProduse = false">Inchide</el-button>
        </span>
    </el-dialog>

    <el-dialog title="Fise inchise" :visible.sync="ArataFiseInchise"  append-to-body="" >
        <el-table size="mini" :data="FiseInchise">
            <el-table-column label="Numar" prop="Id"></el-table-column>
            <el-table-column label="Masina" prop="NumarMasina"></el-table-column>
            <el-table-column label="Client" prop="Client"></el-table-column>
            <el-table-column fixed="right" label="Redeschide">
                <template slot-scope="scope">
                    <el-tooltip content="Redeschide">
                        <el-button size="mini" type="success" icon="el-icon-check"  circle @click="redeschide(scope.row)"></el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>

    <check-dlg @saved="update_fisa" ref='check-dlg'></check-dlg>
    <partener-dlg ref='partener-dlg' @save='refresh_info()'></partener-dlg>
    <produse-dlg ref="produse-dlg" />
    <fisa-dlg ref='fisaIntrareDlg' @save='refresh_info' @close="refresh_info"/>
    <inspectie-dlg ref='inspectie-dlg' @save="finalizare"/>
    <fisa-risc-dialog :Clienti="Clienti" ref="fisa-risc-dialog"/>
    <deviz-dialog ref='deviz'/>
    <hotel-anvelope-dialog ref="hotelAnvelope"/>
        
</base-page>

</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import TitluPagina from '@/widgets/TitluPagina';
import Check360_dialog from '@/pages/mecanic/Check360_dialog.vue';
import Partener_dialog from '@/pages/mecanic/Partener_dialog.vue';
import Produse_rapide_dialog from '@/pages/mecanic/Produse_rapide_dialog.vue';
import Fisa_intrare_dialog from '@/pages/fisa_intrare/Fisa_intrare_dialog.vue';
import Inspectie_finala_dialog from '@/pages/mecanic/Inspectie_finala_dialog.vue';
import Fisa_risc_dialog from '@/pages/mecanic/Fisa_risc_dialog.vue';
import Deviz_deschis_dialog from './Deviz_deschis_dialog';
import Hotel_anvelope_dialog from '@/components/hotel_anvelope/HotelAnvelope_dialog.vue';

import moment from 'moment';


export default {
    name: "dashboard",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'titlu-pagina': TitluPagina,
        'check-dlg': Check360_dialog,
        'partener-dlg': Partener_dialog,
        'produse-dlg': Produse_rapide_dialog,
        'fisa-dlg': Fisa_intrare_dialog, 
        'inspectie-dlg': Inspectie_finala_dialog, 
        'fisa-risc-dialog': Fisa_risc_dialog,
        'deviz-dialog':Deviz_deschis_dialog,
        'hotel-anvelope-dialog':Hotel_anvelope_dialog
    },
    data () {
        return {
            fise: [],
            ArataListaProduse: false,
            ArataFiseInchise: false,
            Produse: [],
            Fisa: {},
            FiseInchise: [],            
            Clienti: [],                      
        }
    },
    methods: {
        async get_info(){            
            this.refresh_info();
        },

        async refresh_info(id_fisa){
            this.select_menu_item('dashboard');
            var response            = await this.post("dashboard_mecanic/index");
            this.fise               = response.FiseIntrare;
            this.ArataListaProduse  = false
            this.ArataFiseInchise   = false 
            this.set_height_for_buttons()

            if(id_fisa > 0)
            {
                let res = await this.post("fisa_intrare/verifica_deviz_deschis",{id_fisa: id_fisa})
                if(res.IdDeviz > 0)
                    // console.log('avem aviz',res.IdDeviz);
                    this.$refs.deviz.show_me(res)
                else    
                    console.log('NU avem aviz',res.IdDeviz);                                        
            }
            // this.$refs.deviz.show_me(id_deviz, masina);
        },

        adauga_masina_noua()
        {
            this.$refs['masina-noua-dlg'].show_me();
        },
        arata_partener_dialog( fisa ){
            this.$refs['partener-dlg'].show_me(fisa);
        },
        arata_produse(item){
            this.$refs['produse-dlg'].show_me(item.Id)
        },
        arata_checklist(item) { 
          if(parseInt(item.IdChecklist) == 0 || item.IdChecklist == null) 
            this.$refs['check-dlg'].show_me(item)
          else 
            this.$refs['check-dlg'].show_me_for_edit(item.IdChecklist);
        },
        arata_deviz(item){
            this.$refs['deviz-dlg'].show_me(item.Id);
        },
        update_fisa (info) {            
          var fisaCautata = this.fise.filter( fisa => parseInt(fisa.Id) === parseInt(info.IdFisaIntrare))
          
          var fisaDeUpdatat = fisaCautata[0]
          fisaDeUpdatat.IdCheklist = info.IdChecklist   
          this.refresh_info()
          
        },
        deschideInspectieDialog(fisa)
        {
            this.Fisa = fisa;
            this.$refs['inspectie-dlg'].show_me(fisa)
        },
 
        async finalizare(item)
        {
            this.$confirm(`Sunteti sigur ?`, 'Info', {type: 'info'})               
                .then(async() => {               
                    let response = await this.post('dashboard_mecanic/finalizare', {fisa: this.Fisa, inspectie: item.inspectie})
                    this.$message({type: 'info', message: response.mesaj});
                    this.$refs['inspectie-dlg'].resetForm()
                    this.refresh_info();
                }).catch(() => {
                        this.$message({type: 'info', message: 'Fisa nu a putut fi finalizata'});                                                                 
                    });                                                                                    
                     
        },
       
        generare_pdf(id_fisa){                               
            window.open("/#/fisa_pdf/" + id_fisa, '_blank');
        },

        fisa_risc(item){     
            this.Clienti = this.$refs.fisaIntrareDlg.get_clienti()           
            this.$refs['fisa-risc-dialog'].show_me(item)
        },

        tire_hotel(item){
            this.$refs.hotelAnvelope.show_me()
        },
    
        adauga_fisa(){
            this.$refs.fisaIntrareDlg.show_me();            
        },

        async redeschidere_fise(){                     
            var response            = await this.post('dashboard_mecanic/get_fise_inchise')
            this.FiseInchise        = response.FiseInchise   
            if(this.FiseInchise.length > 0)
                this.ArataFiseInchise   = true
            else
                this.$message({showClose: true, message: response.Mesaj,type: 'error' });
        },

        async redeschide(item){
            var response = await this.post('dashboard_mecanic/redeschide_fisa', {id_fisa: item.Id})
            this.$message({type: 'info', message: response.Mesaj});
            this.get_info();
        },

        async listeaza_produse(fisa){
            var response = await this.post('dashboard_mecanic/get_produse_programare', {id_programare: fisa.IdProgramare})
            this.Produse = response.Produse
            this.ArataListaProduse = true;
        },
        set_height_for_buttons () {
            this.$nextTick(() => {
                var butoane     = document.getElementsByClassName("car-box-card");
                var maxHeight   = 0;

                for(var i=0; i<butoane.length;i++){
                    if(butoane[i].clientHeight > maxHeight) maxHeight = butoane[i].clientHeight;
                }

                for(var i=0; i<butoane.length;i++){
                    butoane[i].style.height = maxHeight + "px";
                }

                // console.log(maxHeight);
            });
        },
        async stergeFisa(id){
            this.$confirm(`Sunteti sigur ?`, 'Atentie, fisa va fi stearsa!', {type: 'warning'})               
                .then(async() => {               
                    let response = await this.post('dashboard_mecanic/sterge_fisa', {id_fisa:id})
                    this.$message({type: 'info', message: response.mesaj});
                    this.refresh_info();})
                .catch(() => {
                    this.$message({type: 'info', message: 'Stergere anulata'});                                                                 
                });  
        }
    },
    mounted(){
        this.get_info();
    }
};
</script>

<style lang="less" scoped>

.top50{
    margin-top: 20px;
}
.car-box-card{
    margin-bottom:20px;
    margin-right:20px;
    text-align: center;
    background-color: black;
    border-color: black;
    color:white;
    button
    {
        margin: 5px 0px;
    }
}

.pitcar{
    // background-image: url(../../assets/logo-pitcar-alb.png);
    // height: 50px; /* You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: auto; /* Resize the background image to cover the entire container */
    position: relative;

    text-align: center;

    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    
    margin: 30px auto;
}

</style>
