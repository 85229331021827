<template>
    <el-dialog title="Alege un partener" :visible.sync="showPopup" class="my-dialog-class" append-to-body width="80%">
        <el-form label-position="top" :inline="false"  label-width="100%"    ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-select v-model="id_partener" class='full-width'>
                <el-option value='-1' label='Fara partener' ></el-option>
                <el-option v-for="p in parteneri" :value='p.Id' :label='p.Nume' :key="'p-'+p.Id"></el-option>
            </el-select>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>

    </el-dialog>

    

</template>

<script>
    
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Partener_dialog",
        extends: BasePage,
        data () {
            return {
                showPopup: false,
                id_fisa: -1,
                parteneri: [],
                id_partener: ""
            }
        },
        methods: {
            show_me: function(fisa) {
                this.showPopup      = true;
                this.id_fisa        = fisa.Id;
                //var result          = await this.post("dashboard_mecanic/get_info_fisa", { id: this.id_fisa } );
                this.id_partener    = fisa.IdMecanic2;                
            },
            async get_info() {
                var response   = await this.post("dashboard_mecanic/get_parteneri" );
                this.parteneri = response.Parteneri;
            },
            save: async function() 
            {
                await this.post("dashboard_mecanic/save_partener", { id_fisa: this.id_fisa, id_partener: this.id_partener } );
                this.showPopup = false;
                this.$emit("save");
            }
        },
        mounted: function() {
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>